import ResourcePaths from 'config/resources'
import SiteSettings from 'config/settings'
import { ErrorMessagesEnum } from 'helpers/enums/ErrorMessagesEnum'
import { type TMDBStillSizes, type TMDBBackdropSizes, type TMDBPosterSizes, type TMDBLogoSizes } from 'helpers/enums/media/ImageSizesEnum'
import moment from 'moment'
import { type DatabaseOperationResponse } from 'types/data'

/**
 * Generates a full URL for a TMDB poster image based on its path and desired size.
 */
export const buildTMDBPostrerImageUrl = (path: string | null, size: TMDBPosterSizes): string => {
  if (path === null) { return ResourcePaths.NO_IMAGE }
  return `https://image.tmdb.org/t/p/${size}${path}`
}

/**
 * Generates a full URL for a TMDB backdrop image based on its path and desired size.
 */
export const buildTMDBBackdropImageUrl = (path: string | null, size: TMDBBackdropSizes): string => {
  if (path === null) { return ResourcePaths.NO_IMAGE }
  return `https://image.tmdb.org/t/p/${size}${path}`
}

/**
 * Generates a full URL for a TMDB still image based on its path and desired size.
 */
export const buildTMDBStillImageUrl = (path: string | null, size: TMDBStillSizes): string => {
  if (path === null) { return ResourcePaths.NO_IMAGE }
  return `https://image.tmdb.org/t/p/${size}${path}`
}

/**
 * Generates a full URL for a TMDB logo image based on its path and desired size.
 */
export const buildTMDBLogoImageUrl = (path: string | null, size: TMDBLogoSizes): string => {
  if (path === null) { return ResourcePaths.NO_IMAGE }
  return `https://image.tmdb.org/t/p/${size}${path}`
}

/**
 * Generate humanized date from a given date.
 */
export const humanizeDate = (dateIn: string): string => {
  const date = moment(dateIn)

  const now = moment()
  const tomorrow = moment().add(1, 'days')
  const yesterday = moment().subtract(1, 'days')

  if (date.isSame(now, 'day')) {
    return 'today'
  } else if (date.isSame(tomorrow, 'day')) {
    return 'tomorrow'
  } else if (date.isSame(yesterday, 'day')) {
    return 'yesterday'
  }
  return date.fromNow()
}

/**
 * Generate a string for a given episode number and season number.
 */
export const buildEpisodeString = (seasonNumber: number, episodeNumber: number): string => {
  if (seasonNumber < 10) {
    seasonNumber = `0${seasonNumber}` as unknown as number
  }
  return `S${seasonNumber}E${episodeNumber}`
}

/**
 * Check if a given media is followed by the user.
 * For both movies and shows.
 */
export const isMediaFollowed = (storageKey: string, id: number): boolean => {
  const followedShows = JSON.parse(localStorage.getItem(storageKey) ?? '[]')
  return followedShows.includes(id)
}

/**
 * Handle follow action for a given media.
 * For both movies and shows.
 */
export const handleMediaFollowAction = (storageKey: string, mediaId: number, isFollowed: boolean): DatabaseOperationResponse<boolean> => {
  try {
    const followedShows = JSON.parse(localStorage.getItem(storageKey) ?? '[]')

    if (isFollowed) {
      const index = followedShows.indexOf(mediaId)
      if (index > -1) {
        followedShows.splice(index, 1)
      }
    } else {
      if (followedShows.length >= SiteSettings.MEDIA.MAX_WATCHLIST_LENGTH) {
        return { error: ErrorMessagesEnum.MAX_WATCHLIST_LENGTH_REACHED }
      }
      followedShows.push(mediaId)
    }
    localStorage.setItem(storageKey, JSON.stringify(followedShows))
    return { data: !isFollowed }
  } catch (e: any) {
    return { error: e.message }
  }
}
