import { type CreateUserRequest } from 'types/users'
import { type PasswordResetRequest, type RequestPasswordResetRequest, type VerifyEmailRequest } from 'types/auth'
import Client from './client'
import { type User } from '@prisma/client'

class Authentication extends Client {
  api = {
    CREDENTIAL_SIGNUP: '/api/v1/auth/signup',
    REQUEST_VERIFY_EMAIL: '/api/v1/auth/request-verify-email',
    VERIFY_EMAIL: '/api/v1/auth/verify-email',
    REQUEST_RESET_PASSWORD: '/api/v1/auth/request-reset-password',
    RESET_PASSWORD: '/api/v1/auth/reset-password'
  }

  signUp = async (
    request: CreateUserRequest
  ): Promise<User> => {
    return await this.http.post(this.api.CREDENTIAL_SIGNUP, request)
  }

  requestVerifyEmail = async (
    request: VerifyEmailRequest
  ): Promise<boolean> => {
    return await this.http.post(this.api.REQUEST_VERIFY_EMAIL, request)
  }

  requestResetPassword = async (
    request: RequestPasswordResetRequest
  ): Promise<boolean> => {
    return await this.http.post(this.api.REQUEST_RESET_PASSWORD, request)
  }

  resetPassword = async (
    request: PasswordResetRequest
  ): Promise<boolean> => {
    return await this.http.post(this.api.RESET_PASSWORD, request)
  }
}

export default Authentication
