import { type Account, type Affiliate, type User } from '@prisma/client'
import { type PaginationData, type PaginationDataFilter } from 'types/data'
import { type UpdateUserAdminRequest } from 'types/users'
import Client from '../client'

class AdminUser extends Client {
  api = {
    FETCH_USERS: '/api/v1/admin/users',
    UPDATE_USER: '/api/v1/admin/users/{id}',
    DELETE_USER: '/api/v1/admin/users/{id}',
    FETCH_USER_ACCOUNTS: '/api/v1/admin/users/{id}/accounts',
    FETCH_INVITED_USERS: '/api/v1/admin/users/{id}/invites'
  }

  fetchUsers = async (
    request: PaginationDataFilter
  ): Promise<PaginationData<User>> => {
    return await this.http.get(this.api.FETCH_USERS, request)
  }

  updateUser = async (
    id: string,
    request: UpdateUserAdminRequest
  ): Promise<User> => {
    const url = this.buildUrl(this.api.UPDATE_USER, { id })
    return await this.http.patch(url, request)
  }

  deleteUser = async (
    id: string
  ): Promise<User> => {
    const url = this.buildUrl(this.api.DELETE_USER, { id })
    return await this.http.delete(url)
  }

  fetchInvitedUsers = async (
    id: string,
    filter: PaginationDataFilter
  ): Promise<PaginationData<Affiliate>> => {
    const url = this.buildUrl(this.api.FETCH_INVITED_USERS, { id })
    return await this.http.get(url, filter)
  }

  fetchUserAccounts = async (
    id: string,
    filter: PaginationDataFilter
  ): Promise<PaginationData<Account>> => {
    const url = this.buildUrl(this.api.FETCH_USER_ACCOUNTS, { id })
    return await this.http.get(url, filter)
  }
}

export default AdminUser
