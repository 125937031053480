import { type SendAffiliateInviteRequest } from 'types/affiliate'
import Client from './client'

class Affiliate extends Client {
  api = {
    SEND_AFFILIATE_INVITE: '/api/affiliate/send-invite'
  }

  sendAffiliateInvite = async (
    request: SendAffiliateInviteRequest
  ): Promise<boolean> => {
    return await this.http.post(this.api.SEND_AFFILIATE_INVITE, request)
  }
}

export default Affiliate
