import { type MediaSearchRequest, type WatchlistRequest } from 'types/media'
import Client from './client'

import { type PaginationData } from 'types/data'
import { type MovieSmallResponse } from 'types/media/movies'
import { type TVShowSmallResponse } from 'types/media/tvshows'

class Media extends Client {
  api = {
    MEDIA_SEARCH: '/api/v1/media/search',
    FETCH_WATCHLIST: '/api/v1/media/watchlist'
  }

  search = async (
    request: MediaSearchRequest
  ): Promise<PaginationData<TVShowSmallResponse | MovieSmallResponse>> => {
    return await this.http.post(this.api.MEDIA_SEARCH, request)
  }

  fetchWatchlistItems = async (
    request: WatchlistRequest
  ): Promise<PaginationData<TVShowSmallResponse | MovieSmallResponse>> => {
    return await this.http.post(this.api.FETCH_WATCHLIST, request)
  }
}

export default Media
