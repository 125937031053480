import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faCircleInfo, faFilm, faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SiteSettings from 'config/settings'
import { ErrorMessagesEnum } from 'helpers/enums/ErrorMessagesEnum'
import { TMDBPosterSizes } from 'helpers/enums/media/ImageSizesEnum'
import Image from 'next/image'
import Link from 'next/link'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { type MovieSmallResponse } from 'types/media/movies'
import { buildTMDBPostrerImageUrl, handleMediaFollowAction, humanizeDate, isMediaFollowed } from 'utils/client'

interface Props {
  media: MovieSmallResponse
  handleTrailerModal?: (key: string) => void
}

const MovieCard: React.FC<Props> = ({ media, handleTrailerModal }) => {
  const { t } = useTranslation(['common'])

  const isInitiallyFollowed = isMediaFollowed(SiteSettings.MEDIA.MOVIE_WATCHLIST_LS_KEY, media?.tmdbId)

  const [isFollowed, setIsFollowed] = useState(isInitiallyFollowed)
  const handleFollow = useCallback(() => {
    const newValue = handleMediaFollowAction(SiteSettings.MEDIA.MOVIE_WATCHLIST_LS_KEY, media?.tmdbId, isFollowed)
    if (newValue.data !== undefined && typeof newValue.data === 'boolean') {
      setIsFollowed(newValue.data)
      return
    }
    if (newValue.error === ErrorMessagesEnum.MAX_WATCHLIST_LENGTH_REACHED) {
      alert(t(newValue.error as string, { max: SiteSettings.MEDIA.MAX_WATCHLIST_LENGTH }))
      return
    }
    alert(t(newValue.error as string))
  }, [media?.tmdbId, isFollowed])

  return (
    <div className="relative group bg-black overflow-hidden rounded-md transition-transform transform hover:scale-105 h-72">
      <Image
        src={buildTMDBPostrerImageUrl(media?.posterPath, TMDBPosterSizes.MEDIUM)}
        alt={media?.name}
        height={200}
        width={200}
        loading='lazy'
        className="inset-0 w-full h-full object-cover group-hover:opacity-30"
      />

      {/* Follow button, only if release date is after today or is followed */}
      {(new Date(media?.releaseDate) > new Date() || isFollowed) && (
        <button
          className={`absolute top-0 right-0 w-full px-4 py-2 ${isFollowed ? 'bg-primary' : 'bg-secondary'} hover:scale-110 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10`}
          onClick={handleFollow}
        >
          {isFollowed ? t('unfollow') : t('follow')}
        </button>
      )}

      {/* Year in top left */}
      {media?.releaseDate && (
        <div className="absolute top-2 left-2 font-bold text-sm text-primary px-2 bg-white rounded-md">
          {new Date(media?.releaseDate).getFullYear()}
        </div>
      )}
      {/* Rating in top left + 1 */}
      {media?.voteAverage && (
        <div className="absolute top-2 left-16 font-bold text-sm text-black px-2 bg-white rounded-md">
          <FontAwesomeIcon icon={faStar} className="text-yellow-500" /> {media?.voteAverage.toFixed(1)}
        </div>
      )}

      {/* Film icon in top right */}
      <div className="absolute top-2 right-2 text-primary">
        <FontAwesomeIcon icon={faFilm} />
      </div>

      {/* Middle content */}
      <div className="absolute inset-x-0 top-1/3 flex flex-wrap justify-center gap-2 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-40">
        <div className="flex flex-col gap-2">
          {/* Genres */}
          <div className="flex flex-wrap justify-center gap-2 text-white">
            {media?.genres?.map((genre, index) => (
              <span key={index} className="text-xs">{genre}</span>
            ))}
          </div>

          {/* Buttons */}
          <div className="flex justify-center gap-8">
            {/* Info button */}
            <Link href={`/movie/${media.tmdbId}`}>
              <button className="hover:scale-125 rounded-md text-secondary">
                <FontAwesomeIcon size="2x" icon={faCircleInfo} />
              </button>
            </Link>

            {/* Trailer button */}
            {(media.ytTrailerKey && handleTrailerModal) && (
              <button className="text-red-500 hover:scale-125 rounded-md" onClick={() => { handleTrailerModal(media.ytTrailerKey ?? '') }}>
                <FontAwesomeIcon size='2x' icon={faYoutube} />
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Name */}
      <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black bg-opacity-70 backdrop-blur-md">
        <h3 className="font-semibold mb-2 text-white">{media?.name}</h3>
        {media.releaseDate && (
          <div className="text-sm text-black gap-2 px-2 bg-white rounded-sm z-20">
            <span
              title={media?.releaseDate}
            >
              {t(humanizeDate(media?.releaseDate ?? ''))}
            </span>
          </div>
        )}
      </div>

    </div>
  )
}

export default MovieCard
