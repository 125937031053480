import { Form, Formik } from 'formik'
import { ErrorMessagesEnum } from 'helpers/enums/ErrorMessagesEnum'
import { MediaTypeEnum } from 'helpers/enums/media/MediaTypeEnum'
import { debounce } from 'lodash'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { error } from 'services/toastr'
import Yup from 'services/validation'
import { type MediaSearchRequest } from 'types/media'

const placeholders = [
  'Avatar',
  'Lord of the Rings',
  'Matrix',
  'Dark Knight',
  'Game of Thrones',
  'Breaking Bad',
  'Walking Dead',
  'Simpsons'
]

interface Props {
  onSubmit: (values: MediaSearchRequest) => Promise<void>
}

const MediaSearchForm: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation(['common', 'contact'])
  const [placeholder] = useState(placeholders[Math.floor(Math.random() * placeholders.length)])

  const MediaSearchFormSchema = Yup.object().shape({
    query: Yup.string()
      .min(3)
      .max(64)
      .required(t(ErrorMessagesEnum.REQUIRED) ?? 'Required'),
    type: Yup.string()
      .oneOf(Object.values(MediaTypeEnum))
      .nullable()
  })

  // Throttle function using debounce
  const handleSearch = useCallback(
    debounce(async (value) => {
      if (value.length >= 3) {
        try {
          await onSubmit({ query: value })
        } catch (e: any) {
          error(t(e))
        }
      }
    }, 500),
    [onSubmit]
  )
  return (
    <Formik
      initialValues={{ query: '' }}
      onSubmit={onSubmit}
      validationSchema={MediaSearchFormSchema}
    >
      {({ values, setFieldValue }) => (
        <Form className="flex items-center justify-center gap-2 w-full">
          <input
            name='query'
            onChange={(e) => {
              void setFieldValue('query', e.target.value)
              void handleSearch(e.target.value)
            }}
            value={values.query}
            placeholder={placeholder}
            className="flex-grow px-4 py-2 rounded-xl border text-black text-center"
          />
        </Form>
      )}
    </Formik>
  )
}

export default MediaSearchForm
