import { type PaginationData, type PaginationDataFilter } from 'types/data'
import Client from '../client'
import { type Setting } from '@prisma/client'
import { type AdminUpdateSettingsRequest } from 'types/admin/setting'

class AdminSettings extends Client {
  api = {
    FETCH_SETTINGS: '/api/v1/admin/settings',
    UPDATE_SETTINGS: '/api/v1/admin/settings/{id}'
  }

  fetchSettings = async (
    request: PaginationDataFilter
  ): Promise<PaginationData<Setting>> => {
    return await this.http.get(this.api.FETCH_SETTINGS, request)
  }

  updateSettings = async (
    id: string,
    request: AdminUpdateSettingsRequest
  ): Promise<Setting> => {
    const url = this.buildUrl(this.api.UPDATE_SETTINGS, { id })
    return await this.http.patch(url, request)
  }
}

export default AdminSettings
