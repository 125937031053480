import Http from './http'
import AdminAccount from './services/admin/account'
import AdminFile from './services/admin/file'
import AdminPosts from './services/admin/post'
import AdminRole from './services/admin/role'
import AdminSettings from './services/admin/settings'
import AdminUser from './services/admin/user'
import Affiliate from './services/affiliate'
import Authentication from './services/authentication'
import Media from './services/media'
import User from './services/user'
export const http = new Http()
export { http as Http }

// Define the API object used in frontend to access the API.
const Api = {
  affiliate: new Affiliate(http),
  authentication: new Authentication(http),
  media: new Media(http),
  admin: {
    account: new AdminAccount(http),
    file: new AdminFile(http),
    user: new AdminUser(http),
    role: new AdminRole(http),
    settings: new AdminSettings(http),
    posts: new AdminPosts(http)
  },
  user: new User(http)
}

export default Api
