import { type Affiliate } from '@prisma/client'
import { type PaginationData, type PaginationDataFilter } from 'types/data'
import Client from './client'
import { type UpdateUserProfileRequest } from 'types/users'

class User extends Client {
  api = {
    UPDATE_PROFILE: '/api/v1/user',
    FETCH_INVITED_USERS: '/api/v1/users/{id}/invites'
  }

  fetchInvitedUsers = async (
    id: string,
    filter: PaginationDataFilter
  ): Promise<PaginationData<Affiliate>> => {
    const url = this.buildUrl(this.api.FETCH_INVITED_USERS, { id })
    return await this.http.get(url, filter)
  }

  updateProfile = async (
    request: UpdateUserProfileRequest
  ): Promise<User> => {
    return await this.http.patch(this.api.UPDATE_PROFILE, request)
  }
}

export default User
