import { type CreateRoleRequest, type RoleMatrixResponse } from 'types/admin/role'
import Client from '../client'
import { type PaginationDataFilter } from 'types/data'
import { type Role } from '@prisma/client'

class AdminRole extends Client {
  api = {
    FETCH_ROLE_MATRIX: '/api/v1/admin/roles/matrix',
    UPDATE_ROLE_MATRIX: '/api/v1/admin/roles/matrix',
    FETCH_ROLES: '/api/v1/admin/roles',
    CREATE_ROLE: '/api/v1/admin/roles',
    UPDATE_ROLE: '/api/v1/admin/roles/{id}',
    DELETE_ROLE: '/api/v1/admin/roles/{id}'
  }

  fetchRoleMatrix = async (): Promise<RoleMatrixResponse> => {
    return await this.http.get(this.api.FETCH_ROLE_MATRIX)
  }

  updateRoleMatrix = async (
    request: RoleMatrixResponse
  ): Promise<RoleMatrixResponse> => {
    return await this.http.patch(this.api.UPDATE_ROLE_MATRIX, request)
  }

  fetchRoles = async (
    request: PaginationDataFilter
  ): Promise<Role> => {
    return await this.http.get(this.api.FETCH_ROLES, request)
  }

  createRole = async (
    request: CreateRoleRequest
  ): Promise<Role> => {
    return await this.http.post(this.api.CREATE_ROLE, request)
  }

  updateRole = async (
    id: string,
    request: CreateRoleRequest
  ): Promise<Role> => {
    const url = this.buildUrl(this.api.UPDATE_ROLE, { id })
    return await this.http.patch(url, request)
  }

  deleteRole = async (
    id: string
  ): Promise<Role> => {
    const url = this.buildUrl(this.api.DELETE_ROLE, { id })
    return await this.http.delete(url)
  }
}

export default AdminRole
