class Http {
  /**
     * Performs a GET request.
     * @param url
     * @param params
     * @returns
     */
  async get (url: string, params?: any): Promise<any> {
    if (params != null) {
      const query = Object.keys(params).map((key: string) => {
        if (typeof params[key] === 'object') {
          return `${key}=${JSON.stringify(params[key])}`
        } else {
          return `${key}=${params[key]}`
        }
      }).join('&')
      url = `${url}?${query}`
    }

    return await fetch(url, {
      method: 'GET'
    }).then(async res => {
      if (!res.ok) { const json = await res.json(); throw json }
      return await res.json()
    }).then(result => {
      return result
    })
  };

  /**
     * Performs a POST request.
     * @param url
     * @param body
     * @returns
     */
  async post (url: string, body?: any): Promise<any> {
    return await fetch(url, {
      method: 'POST',
      ...(body != null && { body: JSON.stringify(body) })
    }).then(async res => {
      if (!res.ok) { const json = await res.json(); throw json }
      return await res.json()
    }).then(result => {
      return result
    })
  };

  /**
   * Performs a PATCH request
   * @param url
   * @param body
   * @returns
   */
  async patch (url: string, body?: any): Promise<any> {
    return await fetch(url, {
      method: 'PATCH',
      ...(body != null && { body: JSON.stringify(body) })
    }).then(async res => {
      if (!res.ok) { const json = await res.json(); throw json }
      return await res.json()
    }).then(result => {
      return result
    })
  };

  /**
   * Performs a DELETE request
   * @param url
   * @param body
   * @returns
   */
  async delete (url: string, body?: any): Promise<any> {
    return await fetch(url, {
      method: 'DELETE',
      ...(body != null && { body: JSON.stringify(body) })
    }).then(async res => {
      if (!res.ok) { const json = await res.json(); throw json }
      return await res.json()
    }).then(result => {
      return result
    })
  }
}
export default Http
