/**
 * Enum for image sizes for tmdb api
 * "backdrop_sizes": [
  "w300",
  "w780",
  "w1280",
  "original"
],
"logo_sizes": [
  "w45",
  "w92",
  "w154",
  "w185",
  "w300",
  "w500",
  "original"
],
"poster_sizes": [
  "w92",
  "w154",
  "w185",
  "w342",
  "w500",
  "w780",
  "original"
],
"profile_sizes": [
  "w45",
  "w185",
  "h632",
  "original"
],
"still_sizes": [
  "w92",
  "w185",
  "w300",
  "original"
]
 */

export enum TMDBPosterSizes {
  XXSMALL = 'w92',
  XSMALL = 'w154',
  SMALL = 'w185',
  MEDIUM = 'w342',
  LARGE = 'w500',
  XLARGE = 'w780',
  ORIGINAL = 'original'
}

export enum TMDBBackdropSizes {
  SMALL = 'w300',
  MEDIUM = 'w780',
  LARGE = 'w1280',
  ORIGINAL = 'original'
}

export enum TMDBStillSizes {
  SMALL = 'w92',
  MEDIUM = 'w185',
  LARGE = 'w300',
  ORIGINAL = 'original'
}

export enum TMDBLogoSizes {
  XXSMALL = 'w45',
  XSMALL = 'w92',
  SMALL = 'w154',
  MEDIUM = 'w185',
  LARGE = 'w300',
  XLARGE = 'w500',
  ORIGINAL = 'original'
}
