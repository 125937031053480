import { toast, type ToastContent } from 'react-toastify'

/**
 * Display a success toast
 * @param content
 */
export function success (content: ToastContent): void {
  toast(content, {
    toastId: content?.toString(),
    type: 'success'
  })
}

/**
 * Display an error toast
 * @param content
 */
export function error (content: ToastContent): void {
  toast(content, {
    toastId: content?.toString(),
    type: 'error'
  })
}

/**
 * Display a snackbar toast on the bottom left
 * @param content
 */
export function snackbar (content: ToastContent): void {
  toast(content, {
    toastId: content?.toString(),
    type: 'info',
    position: 'bottom-left',
    autoClose: 3000,
    hideProgressBar: true
  })
}
