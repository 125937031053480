import { type Account } from '@prisma/client'
import { type PaginationData, type PaginationDataFilter } from 'types/data'
import Client from '../client'

class AdminAccount extends Client {
  api = {
    FETCH_ACCOUNTS_LIST: '/api/admin/accounts'
  }

  fetchAccountsList = async (
    request?: PaginationDataFilter
  ): Promise<PaginationData<Account>> => {
    return await this.http.get(this.api.FETCH_ACCOUNTS_LIST, request)
  }
}

export default AdminAccount
