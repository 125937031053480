import * as Yup from 'yup'

// Password must contain at least one digit, lowercase letter, uppercase letter and character
export const rPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).+$/

// Add custom methods to Yup Schema
Yup.addMethod<Yup.StringSchema>(Yup.string, 'password', function (): any {
  return this.matches(rPassword, {
    name: 'password',
    message: 'Password must contain at least one digit, lowercase letter and uppercase letter',
    excludeEmptyString: true
  })
    .min(8)
    .max(124)
})
Yup.addMethod<Yup.StringSchema>(Yup.string, 'objectId', function (): any {
  return this.min(24).max(124)
})
Yup.addMethod<Yup.StringSchema>(Yup.string, 'token', function (): any {
  return this.min(256).max(256)
})

export default Yup
