import type Http from 'api/http'

class Client {
  http: Http

  protected api: Record<string, string> = {}

  constructor (http: Http) {
    this.http = http
  }

  buildUrl = (url: string, params: Record<string, string | number>): string => {
    for (const key in params) {
      const value = params[key]
      url = url.replace('{' + key + '}', value.toString())
    }

    return url
  }
}

export default Client
