import { type Post } from '@prisma/client'
import { type CreateUpdatePostRequest } from 'types/admin/post'
import { type PaginationData, type PaginationDataFilter } from 'types/data'
import Client from '../client'

class AdminPosts extends Client {
  api = {
    FETCH_POSTS: '/api/v1/admin/posts',
    CREATE_POST: '/api/v1/admin/posts',
    UPDATE_POST: '/api/v1/admin/posts/{id}',
    DELETE_POST: '/api/v1/admin/posts/{id}'
  }

  fetchPosts = async (
    request: PaginationDataFilter
  ): Promise<PaginationData<Post>> => {
    return await this.http.get(this.api.FETCH_POSTS, request)
  }

  createPost = async (
    request: CreateUpdatePostRequest
  ): Promise<Post> => {
    return await this.http.post(this.api.CREATE_POST, request)
  }

  updatePost = async (
    id: string,
    request: CreateUpdatePostRequest
  ): Promise<Post> => {
    const url = this.buildUrl(this.api.UPDATE_POST, { id })
    return await this.http.patch(url, request)
  }

  deletePost = async (id: string): Promise<Post> => {
    const url = this.buildUrl(this.api.DELETE_POST, { id })
    return await this.http.delete(url)
  }
}

export default AdminPosts
