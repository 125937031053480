import { type PutBlobResult } from '@vercel/blob'
import Client from '../client'

class AdminFile extends Client {
  api = {
    UPLOAD_FILE: '/api/v1/admin/file/upload'
  }

  uploadFile = async (
    request: any
  ): Promise<PutBlobResult> => {
    return await this.http.post(this.api.UPLOAD_FILE, request)
  }
}

export default AdminFile
